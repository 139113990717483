<template>
  <div class="loading-page">
    <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-06-01/19/yuelvhuiaXcQuaAayJ1622546165.jpeg">
  </div>
</template>

<script>
import { isYTApp } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'

const isYtApp = isYTApp()
export default {
  name: 'LandingPageMemberWeek',
  data() {
    return {}
  },
  mounted() {
    if (isYtApp) {
      this.handleAppShare()
      window.RNshareDataChange = this.handleSettingsShare
    } else {
      this.handleSettingsShare()
    }
  },
  methods: {
    /**
     * 是否打开微信
     * 2021-06-01 16:03:41
     * @author SnowRock
     */
    async handleAppShare() {
      await this.$store.dispatch('RNrouter', {
        eventName: 'isShowShare',
        isShow: 1
      })
    },
    /**
     * 处理微信分享数据
     * 2021-06-01 16:04:13
     * @returns {Promise<void>}
     */
    async handleSettingsShare() {
      const url = 'https://web.yuetao.vip/LandingPageMemberWeek'
      const shareInfo = {
        title: '悦淘年中大促',
        desc: '悦豆抵钱花',
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-06-01/15/yuelvhuipY2yhnz4D51622534056.jpg',
        link: url
      }
      if (isYtApp) {
        const appShare = {
          eventName: 'share',
          image: shareInfo.imgUrl, // 分享标题
          title: shareInfo.title,
          des: shareInfo.desc,
          url: shareInfo.link
        }
        this.$store.dispatch('RNrouter', appShare)
      } else {
        wxConfigInit(shareInfo)
        // await this.$store.dispatch('wxConfigInit', shareInfo)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.loading-page {
  img {
    width: 100%;
    height: auto;
  }
}
</style>
